<template>
    <div class="button-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<script>
export default {
    name: "ButtonLoader"
};
</script>

<style lang="scss" scoped>
.button-loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: auto;
}
.button-loader div {
    position: absolute;
    top: calc(50% - 10px);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.button-loader div:nth-child(1) {
    left: 8px;
    animation: button-loader1 0.6s infinite;
}
.button-loader div:nth-child(2) {
    left: 8px;
    animation: button-loader2 0.6s infinite;
}
.button-loader div:nth-child(3) {
    left: 32px;
    animation: button-loader2 0.6s infinite;
}
.button-loader div:nth-child(4) {
    left: 56px;
    animation: button-loader3 0.6s infinite;
}
@keyframes button-loader1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes button-loader3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes button-loader2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
</style>