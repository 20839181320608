<template>
    <div>
        <button class="inline-block mdi mdi-menu text-3xl cursor-pointer focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-20 text-white leading-none p-1" @click="visible = true"></button>
        <div class="flex flex-col justify-between fixed top-0 w-screen h-screen overflow-auto bg-black transition-all z-50 py-8" :class="visible ? 'left-0' : '-left-screen'">
            <ul class="block text-center">
                <li class="block" v-for="(item, index) in data" :key="item.Id" @click="toggleSub(index)">
                    <span class="block text-3xl px-8 text-white py-2" v-if="Array.isArray(item.Sub)">{{ item.Name }}</span>
                    <a v-else class="block text-3xl px-8 text-white py-2" :href="item.Link" :target="item.IsNewWindow == 1 ? '_blank' : '_self'">{{ item.Name }}</a>
                    <ul v-if="Array.isArray(item.Sub)" class="block bg-white bg-opacity-5 overflow-hidden transition-all duration-500" :class="index === sub ? 'max-h-screen' : 'max-h-0'">
                        <li v-for="sub in item.Sub" :key="sub.Id" class="block">
                            <a class="block text-3xl px-8 py-2 text-white" :href="sub.Link" :target="sub.IsNewWindow == 1 ? '_blank' : '_self'">{{ sub.Name }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div></div>
        </div>
        <button class="mdi mdi-close text-4xl leading-none p-1 text-red-600 fixed z-50 bottom-8 transition-all cursor-pointer focus:outline-none focus:ring focus:ring-red-600 focus:ring-opacity-20" :class="visible ? 'right-4 left-auto' : '-left-full'" @click="visible = false"></button>
    </div>
</template>

<script>

export default {
    name: "MobileMenu",
    props: ["data"],
    data() {
        return {
            url: window.location.protocol + "//" + window.location.host,
            visible: false,
            sub: -1
        };
    },
    methods: {
        toggleSub(index) {
            if (this.sub == index) {
                this.sub = -1;
            } else {
                this.sub = index;
            }
        }
    }
};
</script>

<style>
</style>