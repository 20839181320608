<template>
    <div class="bg-gray py-8">
        <div>
            <form class="block" @submit.prevent="send">
                <div v-if="isError && message" class="error-message">{{ message }}</div>
                <div v-if="isSuccess" class="info-message">{{ $t("Wiadomość została wysłana") }}.</div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-12">
                    <div>
                        <div class="form-row">
                            <div class="relative group">
                                <input
                                    v-model="form.FirstName"
                                    type="text"
                                    name="FirstName"
                                    required
                                    :disabled="isLoading"
                                    id="formFirstName"
                                    :placeholder="$t('Imię *')"
                                    class="form-control"
                                    :class="{ 'form-control--error': messages && messages.FirstName }"
                                />
                            </div>
                            <span v-if="messages && messages.FirstName" class="form-control-error">{{ messages.FirstName }}</span>
                        </div>
                    </div>
                    <div>
                        <div class="form-row">
                            <div class="relative group">
                                <input
                                    v-model="form.LastName"
                                    type="text"
                                    name="LastName"
                                    required
                                    :disabled="isLoading"
                                    id="formLastName"
                                    :placeholder="$t('Nazwisko *')"
                                    class="form-control"
                                    :class="{ 'form-control--error': messages && messages.LastName }"
                                />
                            </div>
                            <span v-if="messages && messages.LastName" class="form-control-error">{{ messages.LastName }}</span>
                        </div>
                    </div>
                    <div>
                        <div class="form-row">
                            <div class="relative group">
                                <input
                                    v-model="form.Email"
                                    type="email"
                                    name="Email"
                                    required
                                    :disabled="isLoading"
                                    id="formEmail"
                                    :placeholder="$t('E-mail *')"
                                    class="form-control"
                                    :class="{ 'form-control--error': messages && messages.Email }"
                                />
                            </div>
                            <span v-if="messages && messages.Email" class="form-control-error">{{ messages.Email }}</span>
                        </div>
                    </div>
                    <div>
                        <div class="form-row">
                            <div class="relative group">
                                <input
                                    v-model="form.Phone"
                                    type="text"
                                    name="Phone"
                                    :disabled="isLoading"
                                    id="formPhone"
                                    :placeholder="$t('Numer telefonu')"
                                    class="form-control"
                                    :class="{ 'form-control--error': messages && messages.Phone }"
                                />
                            </div>
                            <span v-if="messages && messages.Phone" class="form-control-error">{{ messages.Phone }}</span>
                        </div>
                    </div>
                    <div class="md:col-span-2">
                        <div class="form-row">
                            <div class="relative group">
                                <textarea
                                    v-model="form.Description"
                                    type="text"
                                    name="Description"
                                    required
                                    :disabled="isLoading"
                                    id="formDescription"
                                    :placeholder="$t('Treść wiadomości *')"
                                    class="form-control resize-none !h-auto"
                                    :class="{ 'form-control--error': messages && messages.Description }"
                                    rows="1"
                                ></textarea>
                            </div>
                            <span v-if="messages && messages.Description" class="form-control-error">{{ messages.Description }}</span>
                        </div>
                    </div>
                    <div class="md:col-span-2">
                        <div class="form-row">
                            <div class="flex items-center">
                                <input
                                    v-model="form.PrivacyPolicy"
                                    type="checkbox"
                                    name="PrivacyPolicy"
                                    id="formPrivacyPolicy"
                                    required
                                    :disabled="isLoading"
                                    class="form-checkbox"
                                    true-value="1"
                                    false-value="0"
                                    :class="{ 'form-control--error': messages && messages.PrivacyPolicy }"
                                />
                                <label for="formPrivacyPolicy" class="form-label text-base mb-0">{{
                                    $t("Wyrażam zgodę na przetwarzanie moich danych osobowych")
                                }}</label>
                            </div>
                            <span v-if="messages && messages.PrivacyPolicy" class="form-control-error px-0">{{ messages.PrivacyPolicy }}</span>
                        </div>
                    </div>
                </div>
                <div class="text-center md:text-right">
                    <button type="submit" class="inline-block bg-primary text-white text-sm ml-auto mr-0 px-8 py-2 transition-all duration-300 ease-in-out hover:bg-opacity-75" :disabled="isLoading">
                        <button-loader v-if="isLoading"></button-loader>
                        <span v-else>{{ $t("Wyślij wiadomość") }}</span>
                    </button>
                </div>
                <input type="hidden" name="Recaptcha" :value="form.Recaptcha" />
            </form>
        </div>
    </div>
</template>

<script>
import ButtonLoader from "./ButtonLoader.vue";

export default {
    name: "ContactForm",
    props: {
        recaptcha: {
            type: Number,
            required: false,
            default: 0,
        },
        recaptchaKey: {
            type: String,
            required: false,
            default: "",
        },
        lang: {
            type: String,
            required: false,
            default: "pl",
        },
    },
    components: { ButtonLoader },
    data() {
        return {
            form: {
                FirstName: "",
                LastName: "",
                Description: "",
                Email: "",
                Phone: "",
                PrivacyPolicy: "0",
                Recaptcha: "",
            },
            isLoading: false,
            isError: false,
            isSuccess: false,
            message: "",
            messages: null,
        };
    },
    methods: {
        async send() {
            this.isLoading = true;
            try {
                const formData = new FormData();
                formData.append("FirstName", this.form.FirstName);
                formData.append("LastName", this.form.LastName);
                formData.append("Email", this.form.Email);
                formData.append("Phone", this.form.Phone);
                formData.append("Description", this.form.Description);
                formData.append("PrivacyPolicy", this.form.PrivacyPolicy);
                formData.append("Recaptcha", this.form.Recaptcha);

                const { data } = await this.$http.post("/sendmail", formData, {
                    headers: {
                        "content-type": "application/x-www-form-urlencoded",
                    },
                });
                if (data.isError) {
                    this.isError = true;
                    this.message = data.message;
                    this.messages = data.messages;
                    this.getRecaptchaToken();
                } else {
                    this.isError = false;
                    this.message = "";
                    this.messages = null;
                    this.isSuccess = true;
                    this.resetForm();
                }
            } catch (error) {
                console.error(error);
            }
            this.isLoading = false;
        },
        getRecaptchaToken() {
            if (this.recaptcha == 1 && typeof grecaptcha !== "undefined") {
                const _this = this;
                grecaptcha.ready(function () {
                    grecaptcha
                        .execute(_this.recaptchaKey, {
                            action: "homepage",
                        })
                        .then(function (token) {
                            _this.form.Recaptcha = token;
                        });
                });
            }
        },
        resetForm() {
            this.form.FirstName = "";
            this.form.LastName = "";
            this.form.Email = "";
            this.form.Phone = "";
            this.form.Description = "";
            this.form.PrivacyPolicy = "0";
            this.form.Recaptcha = "";
            this.getRecaptchaToken();
        },
    },
    created() {
        this.getRecaptchaToken();
        this.$i18n.locale = this.lang;
    },
};
</script>

<style>
</style>